import Image from 'next/image';
import {Link} from "./atoms/link";
import parse, {
  HTMLReactParserOptions,
  domToReact,
  Element,
} from 'html-react-parser';
import {absoluteURL} from '../lib/absolute-url';

import {isRelative} from 'lib/is-relative';
import dynamic from 'next/dynamic'
import {useRouter} from "next/router";

const VimeoPlayer = dynamic(() => import("react-player/vimeo"), {ssr: false});

/**
 * Returns the dom parser options.
 * @param locale
 */
const getOptions = (locale: string | undefined): HTMLReactParserOptions => {
  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      // Handle text elements.
      if (domNode.type === 'text') {
        const htmlRegex = /(<[^>]+>.*?<\/[^>]+>)/g;
        // @ts-ignore
        const parts = domNode.data.split(htmlRegex);

        if (parts.length === 1) {
          // @ts-ignore
          return <>{domNode.data}</>;
        }
        return (
          <>
            {parts.map((part, index) => {
              try {
                if (htmlRegex.test(part)) {
                  return parse(part, {
                    replace: (node) => {
                      if (node instanceof Element) {
                        // Handle multiple inline tags
                        switch (node.name) {
                          case 'sup':
                            return (
                              <sup key={index}>
                                {domToReact(node.children, options)}
                              </sup>
                            );
                          case 'sub':
                            return (
                              <sub key={index}>
                                {domToReact(node.children, options)}
                              </sub>
                            );
                          // Add more tags as needed
                          default:
                            return node;
                        }
                      }
                    }
                  });
                }
                return part;
              } catch (error) {
                return part;
              }
            })}
          </>
        );
      }
      if (domNode instanceof Element) {
        // Replace inline images with `Image` component.
        if (domNode.name === 'img') {
          const {
            src,
            alt,
            class: className,
            width = '100',
            height = '100',
          } = domNode.attribs;

          if (isRelative(src)) {
            return (
              <div className={className}>
                <Image
                  src={absoluteURL(`/${src}`)}
                  width={Number(width)}
                  height={Number(height)}
                  alt={alt}
                  sizes="(min-width: 768px) 625px, 100vw"
                />
              </div>
            );
          }
        }

        // Replace inline links with `Link` component.
        if (domNode.name === 'a') {
          let {href, class: className} = domNode.attribs;

          if (href) {
            if (isRelative(href)) {
              if (href.match(/^\/sites\/default\//)) {
                href = absoluteURL(href);
              }
            }
            return (
              <Link href={href ?? "/"} className={`!text-primary ${className}`}>
                {domToReact(domNode.children)}
              </Link>
            );
          }
        }

        if (domNode.name === 'p') {
          return <p>{domToReact(domNode.children, options)}</p>;
        }

        if (domNode.name === 'sup') {
          return <sup>{domToReact(domNode.children, options)}</sup>;
        }

        // Replace media oembed iframe vimeo urls with vimeo players.
        if (domNode.name === 'iframe') {
          const attribs = domNode.attribs;
          if (attribs.class === 'media-oembed-content' && attribs?.src?.match(/\/media\/oembed/)) {
            const frameUrl = new URL(attribs.src);
            const frameParams = new URLSearchParams(frameUrl.search);

            let src = frameParams.get('url') ?? '';
            frameParams.delete('url');
            if (src?.match(/vimeo\.com/)) {
              // Set the url and parameters.
              const srcUrl = new URL(src);
              const srcParams = new URLSearchParams(srcUrl.search);
              Array.from(frameParams.entries()).forEach(([key, value]) => {
                srcParams.append(key, value);
              });
              srcUrl.search = srcParams.toString();
              let height = attribs.height ?? 700;

              // Display the vimeo player.
              return (
                <div className="w-full">
                  <VimeoPlayer width={'100%'} height={height + 'px'} url={srcUrl.toString()}
                               config={{
                                 playerOptions: {
                                   title: true,
                                   byline: true,
                                   texttrack: locale,
                                   portrait: true,
                                   playsinline: true,
                                   autopause: true,
                                 }
                               }}
                               volume={1} controls style={{maxWidth: '100%', width: '100%'}}/>
                </div>
              )
            }
          }
        }
      }
    },
  };
  return options;
}

interface FormattedTextProps {
  format?: string;
  processed: string;
  value?: string;
  options?: HTMLReactParserOptions,
}

export function FormattedText({processed, options}: FormattedTextProps) {
  const {locale, defaultLocale} = useRouter();
  const useLocale = locale !== defaultLocale ? locale : undefined;
  options = options ?? getOptions(useLocale);
  return <>{parse(processed, options ?? {})}</>;
}
