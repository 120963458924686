import {DrupalNode} from "next-drupal";
import dynamic from "next/dynamic";
import {FormattedText} from "../../formatted-text";
import classNames from "classnames";
import {useRouter} from "next/router";
import Image from "next/image";
import {absoluteURL} from "@/lib/absolute-url";


const Banner = ({node}: {node: DrupalNode}) => {
  const bannerColor = node?.field_alert_banner_color ?? 'orange';
  // @todo this was hard-coded to include flags for AUS. Update this in future if this changes.
  return (
    <div className={classNames("alert-banner sticky top-0 left-0 z-50 w-full px-2 py-1 text-center text-white [&>*]:font-bold md:[&>*]:text-lg text-base flex items-start lg:items-center justify-center gap-2", {
      'bg-accent-red': bannerColor === 'red',
      'bg-bannerOrange': bannerColor === 'orange',
      'bg-green-500': bannerColor === 'green',
      'bg-[#fbfbfb] !text-primary': bannerColor === 'gray',
    })}>
      <Image src={absoluteURL('/sites/default/files/AUS flag.svg')} alt={'Australian Flag'} width={22} height={20} />
      <FormattedText processed={node.body.processed}/>
      <Image src={absoluteURL('/sites/default/files/AUS flag.svg')} alt={'Australian Flag'} width={22} height={20} />
    </div>
  );
}

export function NodeAlertBanner ({node}: {node: DrupalNode}) {
  const {pathname} = useRouter();
  const homeOnly = node?.field_alert_banner_home_only;
  const display = (homeOnly && pathname === '/') || !homeOnly;

  if (!display) {
    return <></>
  }

  return (
    <>
      {node?.field_alert_banner_link?.url ? (
        <a href={node.field_alert_banner_link.url} target="_blank">
          <Banner node={node}/>
        </a>
      ): <Banner node={node}/>}
    </>
  )
}