/**
 * Parses a metatag's schema.org fields into json+ld.
 * @param metatag
 */
export const parseSchemaOrg = (metatag: any[] = []): object => {
  // Break-out any schema.org data into groups.
  const schemaGroup = {};
  metatag?.map(item => {
    if (item?.attributes?.schema_metatag && item?.attributes?.name && item?.attributes?.content) {
      if (!schemaGroup[item?.attributes?.group]) {
        schemaGroup[item.attributes?.group] = {
          '@context': 'https://schema.org',
        };
      }
      schemaGroup[item.attributes?.group][item.attributes.name] = item.attributes.content;
      if (item.attributes?.['@type']) {
        schemaGroup[item.attributes.group]['@type'] = item.attributes['@type'];
      }
    }
  });
  return schemaGroup;
}